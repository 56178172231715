import GreenText from "./GreenText"

export default function Heading({ children }) {
  if (!children) {
    return
  }

  const words = children.split(" ")
  let content

  if (words.length === 1) {
    content = (
      <span>
        <GreenText>{children}</GreenText>
      </span>
    )
  } else if (words.length === 2) {
    content = (
      <span>
        <GreenText>{words[0]}</GreenText> {words[1]}
      </span>
    )
  } else {
    content = (
      <span>
        <GreenText>{words[0]}</GreenText> {words.slice(1, -1).join(" ")}{" "}
        <GreenText>{words[words.length - 1]}</GreenText>
      </span>
    )
  }

  return <div className="heading">{content}</div>
}
