import React from "react"
import { useLocation } from "react-router-dom"
import NavBar from "./NavBar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const location = useLocation()

  const excludePaths = ["/", "/contact", "/merchant", "/pricing", "/faq"]

  const newPage = excludePaths.includes(location.pathname)

  return (
    <div>
      {newPage && <NavBar />}
      {children}
      {newPage && <Footer />}
    </div>
  )
}

export default Layout
