import React from "react"
// import BackgroundImage2 from "../../assets/backgorund_icon_2.png"
// import BackgroundImage3 from "../../assets/backgorund_icon_3.png"
// import BackgroundImage4 from "../../assets/backgorund_icon_4.png"
// import BackgroundImage5 from "../../assets/backgorund_icon_5.png"
// import BackgroundImage6 from "../../assets/backgorund_icon_6.png"
// import BackgroundImage7 from "../../assets/backgorund_icon_7.png"
// import BackgroundImage8 from "../../assets/backgorund_icon_8.png"
// import BackgroundImage9 from "../../assets/backgorund_icon_9.png"

function MainPageContainer({
  mainSectionElement,
  secondSectionElement,
  secondSectionCustomClassName,
  backgroundImage = true
}) {
  return (
    <div>
      <div className="relative">
        {mainSectionElement}
        <img
          className="background_image image_2"
          src={
            "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_2.webp"
          }
          alt="background icon 2"
        />
        <img
          className="background_image image_3"
          src={
            "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_3.webp"
          }
          alt="background icon 3"
        />
      </div>
      <div
        className={`relative ${
          secondSectionCustomClassName
            ? secondSectionCustomClassName
            : "secound_container"
        }`}
      >
        {secondSectionElement}
        <img
          className="background_image image_4"
          src={
            "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_4.webp"
          }
          alt="background icon 4"
        />
        <img
          className="background_image image_5"
          src={
            "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_5.webp"
          }
          alt="background icon 5"
        />
        {backgroundImage && (
          <>
            <img
              className="background_image image_6"
              src={
                "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_6.webp"
              }
              alt="background icon 6"
            />
            <img
              className="background_image image_7"
              src={
                "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_7.webp"
              }
              alt="background icon 7"
            />
            <img
              className="background_image image_8"
              src={
                "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_8.webp"
              }
              alt="background icon 8"
            />
            <img
              className="background_image image_9"
              src={
                "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_9.webp"
              }
              alt="background icon 9"
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MainPageContainer
