import { Col, Row } from "antd"
import React from "react"
import { Heading, Subheading } from "../Text"
import { MainButton } from "../Button"
import GreenLine from "../GreenLine"
// import BackgroundImage1 from "../../assets/backgorund_icon_1.png"
import "./Section.css"

function MainSection({
  headingText,
  subheadingText,
  buttonText,
  onClick,
  imageSrc,
  imageAlt
}) {
  return (
    <main className="main_container">
      <Row justify="space-between" style={{ width: "100%" }}>
        <Col
          xs={{
            span: 24,
            order: 2
          }}
          md={{ span: 12, order: 1 }}
          className="main_left_container"
        >
          {headingText && <Heading>{headingText}</Heading>}
          {headingText && <div style={{ marginTop: "3%" }}></div>}
          {headingText && <GreenLine width="40%" height="4px" />}
          {subheadingText && <div style={{ marginTop: "6%" }}></div>}
          {subheadingText && <Subheading>{subheadingText}</Subheading>}
          {buttonText && (
            <div style={{ marginTop: "10%" }}>
              <MainButton onClick={onClick}>{buttonText}</MainButton>
            </div>
          )}
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 12, order: 2 }}
          className="main_right_container"
        >
          {imageSrc && (
            <img src={imageSrc} alt={imageAlt} className="main_section_image" />
          )}
        </Col>
      </Row>
      <img
        className="background_image image_1"
        src={
          "https://storage.googleapis.com/beecele-images/static-web/backgorund_icon_1.webp"
        }
        alt="background icon 1"
      />
    </main>
  )
}

export default MainSection
