import React, { lazy, Suspense, useEffect } from "react"
import { useLocation, useRoutes } from "react-router-dom"
import { Layout, Loading } from "./components"
import ReactGA from "react-ga4"

const Home = lazy(() => import("./pages/Home"))
const Contact = lazy(() => import("./pages/Contact"))
const Merchant = lazy(() => import("./pages/Merchant"))
const Pricing = lazy(() => import("./pages/Pricing"))
const FAQ = lazy(() => import("./pages/FAQ"))
const Terms = lazy(() => import("./pages/Terms"))
const Privacy = lazy(() => import("./pages/Privacy"))
const MobilePrivacy = lazy(() => import("./pages/MobilePrivacy"))
const SuccessPage = lazy(() => import("./pages/PaymentAlert/SuccessPage"))
const FailedPage = lazy(() => import("./pages/PaymentAlert/FailedPage"))
const PaymentSuccessPage = lazy(() =>
  import("./pages/PaymentAlert/PaymentSuccessPage")
)
const PaymentFailedPage = lazy(() =>
  import("./pages/PaymentAlert/PaymentFailedPage")
)
const StripePending = lazy(() => import("./pages/PaymentAlert/StripePending"))
const Error = lazy(() => import("./pages/Misc"))

const Routes = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/${window.location.pathname}`,
      title: ""
    })
  }, [location])

  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "contact", element: <Contact /> },
    { path: "merchant", element: <Merchant /> },
    { path: "pricing", element: <Pricing /> },
    { path: "faq", element: <FAQ /> },
    { path: "terms", element: <Terms /> },
    { path: "privacy", element: <Privacy /> },
    { path: "mobile-privacy", element: <MobilePrivacy /> },
    { path: "stripe-success", element: <SuccessPage /> },
    { path: "stripe-failed", element: <FailedPage /> },
    { path: "stripe-connect-success", element: <PaymentSuccessPage /> },
    { path: "stripe-connect-failed", element: <PaymentFailedPage /> },
    { path: "stripe-connect-pending", element: <StripePending /> },
    { path: "*", element: <Error /> }
  ])
  
  return (
    <Suspense fallback={<Loading />}>
      <Layout>{element}</Layout>
    </Suspense>
  )
}

export default Routes
