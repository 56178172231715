import Routes from "./Routes"
import { BrowserRouter as Router } from "react-router-dom"
import { ConfigProvider } from "antd"
import { Helmet } from "react-helmet-async"
import { useEffect } from "react"
import ReactGA from "react-ga4"

const config = {
  token: {
    colorPrimary: "#45e5bb"
  }
}

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/${window.location.pathname}`,
      title: "Home"
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Beecele | Subscribe to your favorite restaurants</title>
        <meta
          name="description"
          content="Enjoy exclusive discounts and perks, and elevate your dining experience. Simple, rewarding, and designed for everyone."
        />
      </Helmet>
      <ConfigProvider theme={config}>
        <Router>
          <Routes />
        </Router>
      </ConfigProvider>
    </>
  )
}

export default App
