import React from "react"
import { CardBody, CardHeading } from "../Text"

function ImageCard({ imageSrc, imageAlt, heading, body }) {
  return (
    <div className="card">
      <img src={imageSrc} alt={imageAlt} />
      <CardHeading>{heading}</CardHeading>
      <CardBody>{body}</CardBody>
    </div>
  )
}

export default ImageCard
