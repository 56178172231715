import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Row, Col, Button, Flex } from "antd"
// import LogoWithName from "../../../assets/logo_with_name.png"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { FiGlobe } from "react-icons/fi"
import "./NavBar.css"

const NavBar = () => {
  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navItems = [
    { name: "Merchant", path: "/merchant" },
    { name: "Pricing", path: "/pricing" },
    { name: "FAQ", path: "/faq" },
    { name: "Contact", path: "/contact" }
  ]

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <header>
      <nav className="bottom_shadow nav_container">
        <Row justify="space-between" align="middle">
          <Col>
            <Link
              to="/"
              onClick={() => {
                sidebarOpen && toggleSidebar()
              }}
            >
              <Flex justify="center" align="center">
                <img
                  src={
                    "https://storage.googleapis.com/beecele-images/static-web/logo_with_name.webp"
                  }
                  alt="logo"
                />
              </Flex>
            </Link>
          </Col>
          <Col xs={0} md={12}>
            <Row gutter={8} justify="space-around" align="middle">
              {navItems.map((navItem) => (
                <Col key={navItem.name} align="center" justify="center">
                  <Link
                    to={navItem.path}
                    className={`nav-link ${
                      location.pathname === navItem.path ? "active" : ""
                    }`}
                  >
                    {navItem.name}
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col>
            <Row align="middle" justify="space-around">
              <Col>
                <Button type="text" className="nav-link">
                  <Flex justify="center" align="center">
                    <FiGlobe
                      size={14}
                      style={{
                        backgroundColor: "rgba(69, 229, 187, 0.2)",
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "5px"
                      }}
                    />
                    <div className="lang">English(AU)</div>
                  </Flex>
                </Button>
              </Col>
              <Col xs={5} md={0} style={{ marginRight: "10px" }}>
                <Button
                  type="primary"
                  onClick={toggleSidebar}
                  style={{
                    width: "auto"
                  }}
                >
                  {sidebarOpen ? (
                    <AiOutlineClose size="1.5em" />
                  ) : (
                    <AiOutlineMenu size="1.5em" />
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </nav>
      {sidebarOpen && (
        <div className="mobile_nav_container">
          <Flex
            justify="center"
            align="center"
            className="mobile_nav_drawer"
            onClick={() => {
              sidebarOpen && toggleSidebar()
            }}
          >
            <Col xs={12} md={0} justify="center">
              {navItems.map((navItem) => (
                <Row
                  key={navItem.name}
                  justify="center"
                  align="middle"
                  style={{ margin: "10px 0" }}
                >
                  <Link
                    to={navItem.path}
                    className={`mobile_nav-link ${
                      location.pathname === navItem.path ? "active" : ""
                    }`}
                  >
                    {navItem.name}
                  </Link>
                </Row>
              ))}
            </Col>
          </Flex>
        </div>
      )}
    </header>
  )
}

export default NavBar
