import { Col, Flex, Row } from "antd"
import { AiOutlineCopyright } from "react-icons/ai"
import { Link } from "react-router-dom"
import { FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa"
import "./Footer.css"

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: "#45e5bb",
        height: "100px",
        color: "#ffffff",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Row className="footer-links">
        <Col>
          <Flex justify="space-between" gap={5}>
            <Link to="/terms" className="footer_link">
              Terms
            </Link>
            <span>|</span>
            <Link to="/privacy" className="footer_link">
              Privacy
            </Link>
            <span>|</span>
            <Link to="/mobile-privacy" className="footer_link">
              Mobile-Privacy
            </Link>
            <span>|</span>
            <Link to="/contact" className="footer_link">
              Contact
            </Link>
          </Flex>
        </Col>
      </Row>
      <Row
        style={{
          borderWidth: "0 0 2px 0",
          borderStyle: "solid"
        }}
        className="footer_bottom_wrapper"
        align='center'
      >
        <Col xs={24} sm={24} md={12}>
          <Flex
            style={{ fontSize: "18px", fontWeight: "500", fontFamily: "Inter" }}
            align="center"
            className="footer_left"
            gap={5}
          >
            <AiOutlineCopyright size={20} />
            <div>2024 Beecele | All Rights Reserved</div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Flex className="footer_right" align="center">
            <Link
              to="https://au.linkedin.com/company/beecele?trk=public_profile_topcard-current-"
              className="social_media_icon"
              target="_blank"
            >
              <FaLinkedinIn color="#45e5bb" size={15} />
            </Link>
            <Link
              to="https://twitter.com/Beecele_"
              className="social_media_icon"
              target="_blank"
            >
              <FaTwitter color="#45e5bb" size={15} />
            </Link>
            <Link
              to="https://www.instagram.com/beecele_/"
              className="social_media_icon"
              target="_blank"
            >
              <FaInstagram color="#45e5bb" size={15} />
            </Link>
            <Link
              to="https://www.youtube.com/@Beecele_"
              className="social_media_icon"
              target="_blank"
            >
              <FaYoutube color="#45e5bb" size={15} />
            </Link>
          </Flex>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
