import React from "react"

function GreenLine({ width, height }) {
  return (
    <div
      style={{
        backgroundColor: "#45e5bb",
        height: height,
        width: width
      }}
    ></div>
  )
}

export default GreenLine
